import React, {Fragment} from 'react';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate
} from 'react-router-dom';

export default function PrivateRoute({ children }) {
  const auth=JSON.parse(localStorage.getItem("token"));
  return auth ? children : <Navigate to="/app/login" />;
}