import React, { useEffect, useState }  from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
// import Grid from '@mui/material/Grid';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router';
import LinearProgress from '@mui/material/LinearProgress';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import * as constants from '../Constants';
import CircularProgress from '@mui/material/CircularProgress';
import jwt from 'jwt-decode' 

export default function Clients() {

  const navigate = useNavigate();
  const [loader, setLoader] = useState(1);
  const token=JSON.parse(localStorage.getItem("token"));
   const loggedInUser=jwt(token);
  const [APIData, setAPIData] = useState([]);
  const [records_message, setRecordsMessage] = useState(<CircularProgress />);

useEffect(() => {
  
    if (loggedInUser==null) {
      //navigate("/login");
    }else{
    axios.get(constants.APIURL+`clients`, { params: {
    userId: loggedInUser.user_id,auth:loggedInUser.user_role_id==1 ? "MQ==" : "MA=="
  }
 } )
      .then((response) => {
          setLoader(0);
          setAPIData(response.data);
          if(response.data.data.length==0){
            setRecordsMessage("No Record Found");
          }
      })
    }
}, [])


const refreshPage = () => {
  window.location.reload(false);
}

const [state, setState] = React.useState({
  open: false,
  });
  const { open } = state;

const deleteClient = (id) => { 
  axios.put(constants.APIURL+`client/${id}`,{modified_by:loggedInUser.user_id}).then(() => {
          setState({ open: true });
          refreshPage();
          //navigate("/clients");
      })
}

const handleClose = () => {
  setState({ open: false });
};

console.log(loader);
  return (

    
    <div className="content-wrapper">
      
      { loader ? <Box sx={{ width: '100%' }}><LinearProgress /></Box> : null }
      
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
		autoHideDuration={3000}
        message={"Deleted Successfully!"}
        //key={vertical + horizontal}
		action={
			  <IconButton
				aria-label="close"
				color="inherit"
				sx={{ p: 0.5 }}
				onClick={handleClose}
			  >
				<CloseIcon />
			  </IconButton>
		}
	/>

     
    <section className="content-header">
      <div class="row">
    <div class="col-sm-6">
      <h2>Clients</h2>
      </div>
      <div class="col-sm-6">
      {loggedInUser && loggedInUser.user_role_id==1 ? 
      <div className="content-header" style= {{marginTop:"10px"}} align="right" ><Link to="/addClient"><Button variant="contained">Add Client</Button></Link></div> : ""}
      </div>
      </div>
      <div class="clearfix"></div>
    </section>
   
    <section className="content">
    <div className="box">
    <div className="box-body">
    {APIData.data && APIData.data.length > 0 ?
    <table className="table table-bordered table-striped">
          <thead>
            <tr>
            <th>Company Name</th>
            <th>Name</th>            
            <th>Website</th>
            <th>Pricing Model</th>
            <th>Service</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          {APIData.data && APIData.data.map((client)=>{
            return(
              <tr>
              <td>{client.company_name}</td>
              <td>{client.name}</td>
              <td>{client.website}</td>
              <td>{client.pricing_model}</td>
              <td>{client.service}</td>
              <td dangerouslySetInnerHTML={{__html: client.status}} />
              <td>
              
              <Link to={{pathname: `/reports/${client.id}`}}>
              <IconButton title="View Reports" aria-label="View Reports" size="small"  >
              <AddCircleOutlineIcon fontSize="small" />
              </IconButton>
              </Link>
              {loggedInUser && loggedInUser.user_role_id==1 ? 
              <React.Fragment>
              <Link to={{pathname: `/addClient/${client.id}`}}>
              <IconButton title="Edit" aria-label="Edit" size="small"  >
              <EditIcon fontSize="small" />
              </IconButton>
              </Link>             
                
                <IconButton title="Delete" onClick={() => deleteClient(client.id)}  aria-label="delete" size="small" >
                <DeleteIcon  fontSize="small"/> 
                </IconButton>  </React.Fragment>: ""}
              </td>
               </tr>
            )}
            )}
          </tbody>
        </table>
        : <div align="center"> {records_message} </div> }
    </div>
    </div>
    </section>
  </div>
    
  );
}