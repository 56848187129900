import React, { useEffect, useState }  from 'react';
import Button from '@mui/material/Button';
import { Link, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
// import Grid from '@mui/material/Grid';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router';
import LinearProgress from '@mui/material/LinearProgress';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import * as constants from '../Constants';
import CircularProgress from '@mui/material/CircularProgress';
import jwt from 'jwt-decode' 

export default function Reports() {

  const navigate = useNavigate()
  const { id } = useParams();
  const [loader, setLoader] = useState(1);
  const [records_message, setRecordsMessage] = useState(<CircularProgress />);
  const [APIData, setAPIData] = useState([]);
  //const loggedIn = JSON.parse(localStorage.getItem("token"));
  const token=JSON.parse(localStorage.getItem("token"));
   const loggedInUser=jwt(token);
  const seo_managers_arr=[14,25,22,46,56,73];
  console.log(seo_managers_arr.indexOf(JSON.parse(loggedInUser.designation_id)));
  console.log(loggedInUser.user_role_id);

useEffect(() => {
  axios.get(constants.APIURL+`reports/${id}`, { params: {
    userId: loggedInUser.user_id, designation_id: loggedInUser.designation_id, auth:loggedInUser.user_role_id==1 ? "MQ==" : "MA=="
  }
})
      .then((response) => {
           setLoader(0);
          setAPIData(response.data);
          if(response.data.data.length==0){
            setRecordsMessage("No Record Found");
          }
      })
}, [])


const refreshPage = () => {
  window.location.reload(false);
}

const [state, setState] = React.useState({
  open: false,
  });
  const { open } = state;

const deleteClient = (id) => {  
  const modified_by=loggedInUser.user_id;
  axios.put(constants.APIURL+`reports/${id}`,{modified_by}).then(() => {
          setState({ open: true });
          refreshPage();
          //navigate("/clients");
      })
}
const newid = `${id}`;
const handleClose = () => {
  setState({ open: false });
};

//console.log(APIData);
  return (
    <div className="content-wrapper">
      
      { loader ? <Box sx={{ width: '100%' }}><LinearProgress /></Box> : null }

      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
		    autoHideDuration={3000}
        message={"Deleted Successfully!"}
        //key={vertical + horizontal}
		action={
			  <IconButton
				aria-label="close"
				color="inherit"
				sx={{ p: 0.5 }}
				onClick={handleClose}
			  >
				<CloseIcon />
			  </IconButton>
		}
	/>


    <section className="content-header">
      <div class="row">
    <div class="col-sm-6">
      <h2>Reports</h2>
      </div>
      <div class="col-sm-6">
      <div className="content-header" style= {{marginTop:"10px"}} align="right" >
        <Link to={{pathname: `/addReport/${id}`}}><Button variant="contained">Add Report</Button></Link> &nbsp;&nbsp;
        <Link to={{pathname: `/clients`}}><Button variant="outlined">Back</Button></Link>
        
        </div>
      </div>
      </div>
      <div class="clearfix"></div>
    </section>
   
    <section className="content">
    <div className="box">
    <div className="box-body"> 
    {APIData.data && APIData.data.length > 0 ?
    <table className="table table-bordered table-striped">
          <thead>
            <tr>
            <th width="15%">Month</th>
            <th>Report</th>
            <th>Submitted By</th>
            <th>Submitted Date</th>
            {seo_managers_arr.indexOf(JSON.parse(loggedInUser.designation_id)) == -1  || loggedInUser.user_role_id!=null ?
            <th width="10%">Action</th>
            : "" }
          </tr>
          </thead>
          <tbody>
            
          
          {APIData.data && APIData.data.map((client)=>{

            return(
              <tr>
              <td>{client.month}</td>
              <td><a target="_blank" href={client.report_file}><Button variant="outlined">Download</Button></a></td>
              <td>{client.created_by_name}</td>
              <td>{client.created_date}</td>
              {seo_managers_arr.indexOf(JSON.parse(loggedInUser.designation_id)) == -1  || loggedInUser.user_role_id!=null ?
              <td>
                <IconButton title="Delete" onClick={() => deleteClient(client.id)}  aria-label="delete" size="small" >
                <DeleteIcon  fontSize="small"/>
              </IconButton>
              </td>
              : "" }
               </tr>
            )}
            )}
          </tbody>
        </table>
        : <div align="center"> {records_message} </div> }
    </div>
    </div>
    </section>
  </div>
    
  );
}