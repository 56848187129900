import React, { Fragment } from 'react'
import PublicLayout from "./components/Layouts/PublicLayout";
import ProtectedLayout from "./components/Layouts/ProtectedLayout";
import NotFound from "./components/NotFound";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Link
} from 'react-router-dom';

function App() {
  return (
    <Router>
    <div className="App"> 
      <Routes>
      <Route path='/app/*' element={<PublicLayout/>} />
      <Route path='/*' element={<ProtectedLayout/>} />   
      </Routes>  
    </div>
    </Router>
  );
}

export default App;
