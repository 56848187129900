import React, { useEffect, useState }  from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles'; 
import axios from 'axios';
import { useNavigate } from 'react-router';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import * as constants from '../Constants';


const theme = createTheme();

export default function Login() {

  let navigate = useNavigate();
  // if (!localStorage.getItem("token")){
  //   navigate("/");
  // }

  const [loader, setLoader] = useState(0);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const [state, setState] = React.useState({
    open: false,
    });
    const { open } = state;
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoader(1);
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    // console.log({
    //   email: data.get('username'),
    //   password: data.get('password'),
    // });
    // fetching login details
    
    axios.post(constants.USERAPIURL+'loginAuth',{username,password})
      .then((response) => {
        setLoader(0);
          setState({ open: true });
          console.log(response.data.data);
          if(response.data.token){
            localStorage.setItem('token', JSON.stringify(response.data.token));
            navigate("/");
          }else{
            setState({ open: true });
            setMsg(response.data.message);
          }
      });
  };
  const handleClose = () => {
    setState({ open: false });
  };
  
  return (
    
    <ThemeProvider theme={theme}>
      
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
		autoHideDuration={3000}
        message={msg}
        //key={vertical + horizontal}
		action={
			  <IconButton
				aria-label="close"
				color="inherit"
				sx={{ p: 0.5 }}
				onClick={handleClose}
			  >
				<CloseIcon />
			  </IconButton>
		}
	/>
  
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          { loader ? <Box sx={{ width: '100%' }}><LinearProgress /></Box> : null } <br/>
          
          <img src="../resultfirst-logo-dark.png"/><br/>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email"
              name="username"
              autoComplete="username"
              onChange={(e) => setUsername(e.target.value)}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            
            <Grid container>
              {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
              {/* <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
            </Grid>
          </Box>
          
        </Box>
        
      </Container>
    </ThemeProvider>
    
  );
}