import React from 'react';
import { Link } from 'react-router-dom'
import jwt from 'jwt-decode' 

class Header extends React.Component
{
  render()
  {
    const token=JSON.parse(localStorage.getItem("token"));
    let loggedInUser=null;
    if(token){
      loggedInUser = jwt(token);
    }
    return (
<div>
  <header className="main-header">
    <Link to="/"  className="logo">
      <img src="/resultfirst-logo.png" width="160"/> 
    </Link>
    <nav className="navbar navbar-static-top">
      <a href="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
        <span className="sr-only">Toggle navigation</span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </a>
    </nav>
  </header>

  <aside className="main-sidebar">
    <section className="sidebar" style={{height: "auto"}}>
      <div className="user-panel">
        <div className="pull-left image">
          <img src={loggedInUser ? loggedInUser.profile_pic : "/dist/img/user2-160x160.jpg"} className="img-circle" alt="User Image"/>
        </div>
        <div className="pull-left info">{console.log(loggedInUser)}
          <p>{loggedInUser ? loggedInUser.user_full_name : "Admin"}</p>
          <a href="#"><i className="fa fa-circle text-success"></i> Online</a>
        </div>
        <br/> <br/> <br/>
      </div>
    
      <ul className="sidebar-menu" data-widget="tree">
        <li className="header">MAIN NAVIGATION</li>
        {/* <li className="treeview">
          <a href="#">
            <i className="fa fa-dashboard"></i> <span>Dashboard</span>
            <span className="pull-right-container">
              <i className="fa fa-angle-left pull-right"></i>
            </span>
          </a>
          <ul className="treeview-menu">
            <li><a href="index.html"><i className="fa fa-circle-o"></i> Dashboard v1</a></li>
            <li><a href="index2.html"><i className="fa fa-circle-o"></i> Dashboard v2</a></li>
          </ul>
        </li> */}
        <li>
        <Link to="/"><i className="fa fa-th"></i> <span>Dashboard</span></Link>
        </li>
        <li>
        <Link to="/clients">
            <i className="fa fa-th"></i> <span>Clients</span>
          </Link>
        </li>
        <li>
        <Link to="/logout">
            <i className="fa fa-th"></i> <span>Logout</span>
          </Link>
        </li>
      </ul>
    </section>
  </aside>
  </div>
  )
  }
}
export default Header;

