import React, { useEffect, useState }  from 'react';
import { useNavigate } from 'react-router';

export default function Logout() {

  let navigate = useNavigate();
  
  useEffect(() => {
    localStorage.removeItem("token");
    navigate("/app/login");  
  }, [])
  
  return( <div></div>)
}