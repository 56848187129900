import React, { useEffect, useState }  from 'react';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import * as constants from '../Constants';
import Select from 'react-select'; 
import CircularProgress from '@mui/material/CircularProgress';
import jwt from 'jwt-decode' 

export default function Dashboard() {

	let navigate = useNavigate();
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [company_name, setCompany] = useState('');
    const [website, setWebsite] = useState('');
    const [service, setService] = useState('');
    //const [password, setPassword] = useState('');
    const [pricing_model, setPricingModel] = useState('');
    const [status, setStatus] = useState('');
    const [assignto, setAssignto] = useState();
	const token=JSON.parse(localStorage.getItem("token"));
   const loggedInUser=jwt(token);
	const [checkedItems, setCheckedItems] = useState({}); //plain object as state

	
	const [state, setState] = React.useState({
		open: false,
	  });

	const { open } = state;
	const { id } = useParams();
	const newid = `${id}`;
	const created_by= loggedInUser.user_id;
	 	 
	const [APIData, setAPIData] = useState([]);
	const [UserData, setUserData] = useState([]);
	// const selectedOptions = [
	// 	{ value: 'chocolate', label: 'Chocolate' },
	// 	{ value: 'strawberry', label: 'Strawberry' },
	// 	{ value: 'vanilla', label: 'Vanilla' }
	//   ]
	 // let selectedOptions =[];
	  
	 const handleChange = (e) => { 
		setCheckedItems({...checkedItems, [e.target.value] : e.target.checked });
		
	  };

	//console.log({checkedItems});

	  
	useEffect(() => {

	axios.get(constants.USERAPIURL+`users_dropdown`)
	  .then((response) => { 
		setUserData(response.data);
		  console.log(response.data);
	  });
console.log(isNaN(id));
	  if(isNaN(id)==false){
	   axios.get(constants.APIURL+`clients/${id}`)
		.then((response) => { 
			setAPIData(response.data);
			setName(response.data.data.name);
			setEmail(response.data.data.email);
			setPhone(response.data.data.phone);
			setCompany(response.data.data.company_name);
			setWebsite(response.data.data.website);
			setService(response.data.data.service);
			//setPassword(response.data.data.password);
			setPricingModel(response.data.data.pricing_model);
			setStatus(response.data.data.status);
			setCheckedItems(JSON.parse(response.data.data.assignto_selected?response.data.data.assignto_selected:{}));
			console.log({checkedItems});
		});
	}
		  
	},

	[])
	
	const createClient = (e) => { 
		e.preventDefault();
		console.log({checkedItems});
		axios.post(constants.APIURL+`clients`, {
			name,email,phone,company_name,website,service,pricing_model,status,created_by,checkedItems
		}).then(() => {
            setState({ open: true });
			navigate("/clients");
        })
	}    
	
	const updateClient = (e) => {
		e.preventDefault();
		axios.put(constants.APIURL+`clients/${id}`, {
			name,email,phone,company_name,website,service,pricing_model,status,created_by,checkedItems
		}).then(() => {
            setState({ open: true });
			navigate("/clients");
        })
	}    
	

	const handleClose = () => {
		setState({ open: false });
	};

	
    return ( 
        <div className="content-wrapper">

	<Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
		autoHideDuration={3000}
        message={"Created Successfully!"}
        //key={vertical + horizontal}
		action={
			  <IconButton
				aria-label="close"
				color="inherit"
				sx={{ p: 0.5 }}
				onClick={handleClose}
			  >
				<CloseIcon />
			  </IconButton>
		}
	/>	
        <section className="content-header">
          <h1>
            Add/Edit Client
          </h1>
        </section>

		{ isNaN(id)==true || APIData.data && APIData.data.name ? (
        <section className="content">
            <div className="box">    <br/>
        <form className="member-form" id="member-form" name="member-form">
                    <div className="col-sm-4">
						<div className="form-group">
							<label>Name</label>
							<input type="text" className="form-control" name="name" defaultValue={APIData.data && APIData.data.name?APIData.data.name:""} onChange={(e) => setName(e.target.value)} required/>
						</div>
                        </div>
                        <div className="col-sm-4">
						<div className="form-group">
							<label>Email</label>
							<input type="email" className="form-control" name="email" defaultValue={APIData.data && APIData.data.email?APIData.data.email:""} onChange={(e) => setEmail(e.target.value)} />
						</div>
                        </div>
                        <div className="col-sm-4">
						<div className="form-group">
							<label>Phone</label>
							<input type="text" className="form-control" name="phone" defaultValue={APIData.data && APIData.data.phone?APIData.data.phone:""} onChange={(e) => setPhone(e.target.value)} />
						</div>
                        </div>
                        <div className="col-sm-4">
						<div className="form-group">
							<label>Company Name</label>
							<input type="text" className="form-control" name="company_name" defaultValue={APIData.data && APIData.data.company_name?APIData.data.company_name:""} onChange={(e) => setCompany(e.target.value)}/>
						</div>
                        </div>
                        <div className="col-sm-4">
						<div className="form-group">
							<label>Website</label>
							<input type="text" className="form-control" name="website" defaultValue={APIData.data && APIData.data.website?APIData.data.website:""} onChange={(e) => setWebsite(e.target.value)} />
						</div>
                        </div>
                        <div className="col-sm-4">
						<div className="form-group">
							<label>Service</label>
							<input type="text" className="form-control" name="service" defaultValue={APIData.data && APIData.data.service?APIData.data.service:""}  onChange={(e) => setService(e.target.value)} />
						</div>
                        </div>
                        {/* <div className="col-sm-4">
                        <div className="form-group">
								<label>Password</label>
								<input type="password" className="form-control password valid" minLength="8" name="password" defaultValue={APIData.data && APIData.data.password?APIData.data.password:""} onChange={(e) => setPassword(e.target.value)} />
							</div>
                         </div>    */}
                         <div className="col-sm-4">	
                        <div className="form-group">
							<label>Pricing Model</label>
							<select defaultValue={APIData.data && APIData.data.pricing_model} className="form-control" name="pricing_model" required onChange={(e) => setPricingModel(e.target.value)} >
								<option value="">Select</option>
								<option value="1">Flat Monthly Basis</option>
								<option value="2">PFP Model</option>
							</select>
						</div>
						</div>
						
						<div className="col-sm-4">
						<div className="form-group">
							<label>Status</label>
							<select defaultValue={APIData.data && APIData.data.status}  className="form-control" name="status" required onChange={(e) => setStatus(e.target.value)}>
								<option value="">Select</option>
								<option value="1">Active</option>
								<option value="0">Inactive</option>
							</select>
						</div>
                        </div>
						<div className="clearfix"></div><hr/>
						<div className="col-sm-12">
						<div className="form-group">
							<label>Assign To</label><br/><br/>
							<div style={{height:"350px", overflowY:"auto"}}>
							{UserData.data && UserData.data.map((user,index)=>{ 
								return( 
									<div><Checkbox
									id={"chk"+index}
									checked={checkedItems[user.value]?checkedItems[user.value]:false}
									name="chk[]"
									value={user.value}
									onChange={handleChange}
									inputProps={{ 'aria-label': 'controlled' }}
								  /><label for={"chk"+index}>{user.label}</label></div>
									)}
								)}
							</div>


							{/* <select style={{height:"300px"}} multiple defaultValue={APIData.data && APIData.data.assignto}  className="form-control" name="assignto[]" onChange={handleAssignto}>
								<option value="">Select</option>
								{UserData.data && UserData.data.map((user)=>{
								return(
										<option value={user.value}>{user.label}</option>
									)}
								)}
							</select> */}
							
							{/* <Select
								value={APIData.data && APIData.data.assignto}
								isMulti
								name="assignto"
								onChange={handleAssignto}
								options={UserData.data}
								className="basic-multi-select"
								classNamePrefix="select"
							/> */}
						</div>
                        </div>
						
						<div className="clearfix"></div><br/>
                        <div className="col-sm-12">
						<div className="form-group">
							{ newid!='undefined' ? <Button variant="contained" type="submit" onClick={updateClient}  name="submit">Update</Button> : <Button variant="contained" className="btn btn-primary" onClick={createClient} type="submit" name="submit">Submit</Button> }
							&nbsp;&nbsp;
							<Link to="/clients"><Button variant="outlined">Back</Button></Link>
						</div>
					</div>
                </form>
                <div className="clearfix"></div><br/>
                </div>
        </section>
	)
	:	(<section className="content">
			<div className="box" align="center"> 
			<br/> <CircularProgress /> <br/> 
			</div>
		</section>) }
      </div>
	
    )	
}
