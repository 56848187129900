import React from 'react';
import Login from "../Login";
import NotFound from "../NotFound";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link
} from 'react-router-dom';


const PublicLayout = (props) => <div>
  <Routes>
    <Route exact path='/login' element={<Login/>} />
    <Route path='/404' element={<NotFound/>} /> 
    <Route path='*' element={<NotFound/>} />  
  </Routes>
  </div>

export default PublicLayout