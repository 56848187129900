import React, { useEffect, useState }  from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import * as constants from '../Constants';
import CircularProgress from '@mui/material/CircularProgress';
import jwt from 'jwt-decode' 

export default function Dashboard() {

  const navigate = useNavigate()
  
  const [APIData, setAPIData] = useState([]);
  const token=JSON.parse(localStorage.getItem("token"));
  let loggedInUser=null;
  if(token){
    loggedInUser = jwt(token);
  }

    useEffect(() => {
        axios.get(constants.APIURL+`getcounts`, { params: {
           userId: (loggedInUser?loggedInUser.user_id:""),
           designation_id: (loggedInUser?loggedInUser.designation_id:""),
           auth:(loggedInUser && loggedInUser.user_role_id==1 ? "MQ==" : "MA==")
       }
     })
          .then((response) => {
              //setLoader(0);
              setAPIData(response.data);
          })
        
    }, [])
    
    return (
        <div className="content-wrapper">
        <section className="content-header">
    <div class="row">
    <div class="col-sm-12">
      <h2>Dashboard</h2>
      </div>
      </div>
      <div class="clearfix"></div>
    </section>
          
        <section className="content">

        <div className="row">
        <div className="col-lg-3 col-xs-6">
          <div className="small-box bg-aqua">
            <div className="inner">
              <h3>{APIData.data && APIData.data.clientsCount?APIData.data.clientsCount:
              0}</h3>
              <p>Total Clients</p>
            </div>
            <div className="icon">
              <i className="ion ion-person-add"></i>
            </div>
            <a className="small-box-footer"></a>
          </div>
        </div>


        <div className="col-lg-3 col-xs-6">
          <div className="small-box bg-green">
            <div className="inner">
            <h3>{APIData.data && APIData.data.reportsCount?APIData.data.reportsCount:
              0}</h3>
              <p>Total Reports</p>
            </div>
            <div className="icon">
              <i className="ion ion-stats-bars"></i>
            </div>
            <a className="small-box-footer"></a>
          </div>
        </div>


        <div className="col-lg-3 col-xs-6">
          <div className="small-box bg-yellow">
            <div className="inner">
            <h3>{APIData.data && APIData.data.monthReportsCount?APIData.data.monthReportsCount:
              0}</h3>
              <p>This Month Reports</p>
            </div>
            <div className="icon">
              <i className="ion ion-pie-graph"></i>
            </div>
            <a className="small-box-footer"></a>
          </div>
        </div>
        {/* <div className="col-lg-3 col-xs-6">
          <div className="small-box bg-red">
            <div className="inner">
              <h3>65</h3>

              <p>Reports</p>
            </div>
            <div className="icon">
              <i className="ion ion-pie-graph"></i>
            </div>
            <a href="#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"></i></a>
          </div>
        </div> */}
      </div>
        </section>
      </div>
    );
  }