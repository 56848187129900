import React, { useEffect, useState }  from 'react';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import * as constants from '../Constants';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import jwt from 'jwt-decode' 

export default function AddReport() {

    
	let navigate = useNavigate();
	const [file, setFile] = useState('');
	//const [month, setMonth] = useState('');
	const [startDate, setStartDate] = useState(new Date());
	const [selectedFile, setSelectedFile] = useState('');
	const token=JSON.parse(localStorage.getItem("token"));
   const loggedInUser=jwt(token);

	const [state, setState] = React.useState({
		open: false,
	  });

	const { open } = state;
	const { id } = useParams();
	const newid = `${id}`;

	//console.log(APIData.data.name);
	const handleFileSelect = (event) => {
		setSelectedFile(event.target.files[0])
	  }

	const handleSubmit = (e) => { 
		e.preventDefault();	
		const clientId=id;
		const formData = new FormData();
		formData.append("file", selectedFile);
		formData.append("month", moment(startDate).format("YYYY-MM"));
		formData.append("created_by", loggedInUser.user_id);
		formData.append("created_by_name", loggedInUser.user_full_name);
		formData.append("clientId", clientId);
		axios.post(constants.APIURL+'reports', formData).then(() => {
            setState({ open: true });
			navigate(`/reports/${id}`);
        })
	}    
	
	
	const handleClose = () => {
		setState({ open: false });
	};
	

	  
    return (
        <div className="content-wrapper">

	<Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
		autoHideDuration={3000}
        message={"Created Successfully!"}
		action={
			  <IconButton
				aria-label="close"
				color="inherit"
				sx={{ p: 0.5 }}
				onClick={handleClose}
			  >
				<CloseIcon />
			  </IconButton>
		}
	/>	
        <section className="content-header">
          <h1>
            Add/Edit Report
          </h1>
        </section>
        <section className="content">
            <div className="box">    <br/>
        <form className="member-form" id="member-form" name="member-form" onSubmit={handleSubmit}>
		<div className="col-sm-4">
		<div className="form-group">
                <label>Month:</label>
				<DatePicker dateFormat="yyyy-MM" selected={startDate}  className="form-control" onChange={(date) => setStartDate(date)}   />
              </div>
			  </div>
					<div className="col-sm-4">
						<div className="form-group">
							<label>Upload Report</label>
							<input type="file" className="form-control" name="report_file"  onChange={handleFileSelect} required/>
						</div>
                        </div>
                        
                        <div className="col-sm-12">
						<div className="form-group">
							<Button variant="contained" type="submit" className="btn btn-primary"  name="submit">Submit</Button>
							&nbsp;&nbsp;
							<Link to={{pathname: `/reports/${id}`}}><Button variant="outlined">Back</Button></Link>
						</div>
					</div>
                </form>
                <div className="clearfix"></div><br/>
                </div>
        </section>
      </div>
    )
}
