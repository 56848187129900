import React from 'react';

class Footer extends React.Component
{
  render()
  {
    return (
        <div>
        <footer className="main-footer">
        All rights reserved.
      </footer>
        </div>
    )
  }
}
export default Footer;