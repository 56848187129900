import React from 'react';
import Header from "../Header";
import Footer from "../Footer";
import Dashboard from "../Dashboard";
import Client from "../Client";
import AddClient from "../AddClient";
import Reports from "../Reports";
import AddReport from "../AddReport";
import Logout from "../Logout";
import NotFound from "../NotFound";
import PrivateRoute from "../Layouts/Private/PrivateRoute";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Link
} from 'react-router-dom';


const ProtectedLayout = (props) => <div>
<Header/>
 <Routes>
    <Route exact path='/' element={<PrivateRoute><Dashboard/></PrivateRoute>}  />
    <Route exact path='/clients' element={<PrivateRoute><Client/></PrivateRoute>} />
    <Route path='/reports' element={<PrivateRoute><Reports/></PrivateRoute>} />  			
    <Route path='/logout' element={<PrivateRoute><Logout/></PrivateRoute>} />
    <Route path='/addClient' element={<PrivateRoute><AddClient/></PrivateRoute>} />
    <Route path='/addClient/:id' element={<PrivateRoute><AddClient/></PrivateRoute>} />
    <Route path='/addReport/:id' element={<PrivateRoute><AddReport/></PrivateRoute>} />
    <Route path='/reports/:id' element={<PrivateRoute><Reports/></PrivateRoute>} />
    <Route path='*' element={<Navigate to="/app/404"/>} />
 </Routes>
<Footer/>
</div>

export default ProtectedLayout
